import { Helmet } from 'react-helmet-async'
import type { APIProfessional } from '../../models'
import { useConfig } from '../../hooks/config'
import styles from './Professionals.module.css'
import classNames from 'classnames'

function ProfessionalCard({ professional }: { professional: APIProfessional }) {
  return (
    <div
      className={'d-flex align-items-start mt-5 d-flex flex-column flex-lg-row'}
    >
      <div className={styles.ProImageContainer}>
        <img
          className={styles.ProImage}
          src={professional.photo?.file?.url+'?w=300&q=90'}
          alt={professional.name}
        />
      </div>
      <div className="mt-4 ms-4">
        <h2 className={classNames('text-primary', styles.ProTitle)}>
          {professional.name}
        </h2>
        {professional.role && (
          <div className={styles.ProRole}>
            <b className="text-secondary">{professional.role}</b>
          </div>
        )}
        <p style={{ whiteSpace: 'pre-wrap' }}>{professional.description}</p>
      </div>
    </div>
  )
}

export default function Professionals() {
  const config = useConfig()
  return (
    <>
      <Helmet>
        <title>I Professionisti • Studio Legale Avvocato Micol Missana</title>
      </Helmet>
      <div className="container padding-top-navbar minh-100 pb-5">
        <h1 className="mt-3 text-primary">La rete di professionisti</h1>
        {config.professionals.map((p, i) => (
          <ProfessionalCard professional={p} key={p.id} />
        ))}
      </div>
    </>
  )
}
