import dayjs from 'dayjs'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import RichText from '../../components/RichText'
import { useArticle } from '../../hooks/article'

export default function Article() {
  const { slug } = useParams()
  const article = useArticle(slug!)
  return (
    <>
      <Helmet>
        <title>{article.title} • Studio Legale Avvocato Micol Missana</title>
      </Helmet>
      <div className="container padding-top-navbar minh-100 pb-5">
        <h1 className="mt-3 text-primary mb-1">{article.title}</h1>
        <div className="text-primary mb-4">
          {dayjs(article.date).format('DD/MM/YYYY')}
        </div>
        <RichText doc={article.content} />
      </div>
    </>
  )
}
