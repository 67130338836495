import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { useConfig } from '../../hooks/config'
import { Helmet } from 'react-helmet-async'

export default function Articles() {
  const { articles } = useConfig()
  return (
    <>
      <Helmet>
        <title>Articoli • Studio Legale Avvocato Micol Missana</title>
      </Helmet>
      <div className="container padding-top-navbar minh-100 pb-5">
        <h1 className="mt-3 text-primary mb-4">Articoli</h1>
        {articles.map((a) => (
          <div key={a.id} className="mb-3">
            <Link className="clean" to={`/articolo/${a.slug}`}>
              <h2>{a.title}</h2>
            </Link>
            {a.date && (
              <div className="text-primary">
                {dayjs(a.date).format('DD/MM/YYYY')}
              </div>
            )}
            <div>{a.description}</div>
          </div>
        ))}
      </div>
    </>
  )
}
