import { Helmet } from 'react-helmet-async'
import { useConfig } from '../../hooks/config'
import { APIService } from '../../models'
import styles from './WorkingAreas.module.css'

function ServiceCard({ service }: { service: APIService }) {
  return (
    <div
      className={'d-flex align-items-start mt-5 d-flex flex-column flex-lg-row'}
    >
      <div className={styles.ServiceImageCont}>
        <img
          className={styles.ServiceImage}
          src={service.image?.file?.url + '?w=250&q=100'}
          alt={service.name}
        />
      </div>
      <div className="ms-0 ms-lg-4 pt-3 pt-lg-0">
        <h4 className={'text-secondary text-center'}>{service.name}</h4>
        <p className="px-1" style={{ whiteSpace: 'pre-wrap' }}>
          {service.description}
        </p>
      </div>
    </div>
  )
}

export default function WorkingAreas() {
  const config = useConfig()
  return (
    <>
      <Helmet>
        <title>Aree di competenza • Studio Legale Avvocato Micol Missana</title>
      </Helmet>
      <div className="container padding-top-navbar minh-100 pb-5">
        <h1 className="mt-3 text-primary">Aree di competenza</h1>
        {config.services.map((service, i) => (
          <ServiceCard key={i} service={service} />
        ))}
      </div>
    </>
  )
}
