import { ReactNode, Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import RichText from '../../components/RichText'
import { useConfig } from '../../hooks/config'
import type { APIPlace } from '../../models'
import styles from './Contact.module.css'

interface ContactItemProps {
  icon: string
  children: ReactNode
}

function ContactItem({ icon, children }: ContactItemProps) {
  return (
    <div className="d-flex align-items-center mt-2">
      <i className={`bi bi-${icon} text-primary me-2`}></i>
      {children}
    </div>
  )
}

function PlaceItem({ place }: { place: APIPlace }) {
  return (
    <div className="col-12 col-lg-6 mt-2">
      <h5>{place.title}</h5>
      <div>{place.address}</div>
      {place.mapsUrl && (
        <iframe
          className="mt-2 mb-2"
          title="contatti"
          src={place.mapsUrl}
          width={'100%'}
          height={350}
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      )}
      <b className="text-primary">
        <i className="bi bi-car-front-fill"></i> Parcheggi
      </b>
      <p className="m-0 mt-1" style={{ whiteSpace: 'pre-wrap' }}>
        {place.parking}
      </p>
    </div>
  )
}

export default function Contact() {
  const config = useConfig()
  return (
    <>
      <Helmet>
        <title>Contatti • Studio Legale Avvocato Micol Missana</title>
      </Helmet>
      <div className="container padding-top-navbar minh-100 pb-5">
        <h1 className="mt-3 text-primary mb-3">Contatti</h1>
        <ContactItem icon="envelope-fill">
          <div className="d-flex flex-column flex-lg-row">
            {config.emails.map((email, i) => (
              <Fragment key={i}>
                <div>
                  <a href={`mailto:${email}`}>{email}</a>
                </div>
                {i !== config.emails.length - 1 && (
                  <div className="mx-2 d-none d-lg-block">{'-'}</div>
                )}
              </Fragment>
            ))}
          </div>
        </ContactItem>
        <ContactItem icon="telephone-fill">
          {config.phones.map((phone, i) => (
            <span key={i}>
              <a href={`tel:${phone}`}>{phone}</a>
              {i !== config.phones.length - 1 && (
                <span className="mx-2">{'-'}</span>
              )}
            </span>
          ))}
        </ContactItem>
        <h2 className="text-primary mt-3 mb-2">Sedi</h2>
        <div className="row">
          {config.places.map((place) => (
            <PlaceItem place={place} key={place.id} />
          ))}
        </div>
        <h2 className="text-primary mt-3">Orari d'apertura</h2>
        {config.timetables && (
          <div className={styles.TimeTables}>
            <RichText doc={config.timetables} />
          </div>
        )}
      </div>
    </>
  )
}
