import { Helmet } from 'react-helmet-async'
import RichText from '../../components/RichText'
import { useConfig } from '../../hooks/config'

export default function Office() {
  const config = useConfig()
  return (
    <>
      <Helmet>
        <title>Lo Studio • Studio Legale Avvocato Micol Missana</title>
      </Helmet>
      <div className="container padding-top-navbar minh-100 pb-5">
        <h1 className="mt-3 text-primary">Lo Studio</h1>
        {config.studioText && (
          <div className="mt-4">
            <RichText doc={config.studioText} />
          </div>
        )}
        {config.studioTextBottom && (
          <div className="mt-4">
            <RichText doc={config.studioTextBottom} />
          </div>
        )}
      </div>
    </>
  )
}
